const firebaseConfig = {
  apiKey: 'AIzaSyCCv25Qxx1U0uUw3j2R8hRj3vZMUVaOJwo',
  authDomain: 'crypo-trackon.firebaseapp.com',
  projectId: 'crypo-trackon',
  storageBucket: 'crypo-trackon.appspot.com',
  messagingSenderId: '27847307486',
  appId: '1:27847307486:web:7440848a173d72a82d524f',
};

export default firebaseConfig;
